import React, { useState } from "react"
import TextField, { CreditDateField } from "../../../components/textfield"
import { Section } from "./Section"
import { Content, FormErrors } from "../index"
import { PaymentOptions } from "../../../data/plans"
import { XIcon } from "@heroicons/react/outline"

import PayPalLogo from "../../../images/logo-paypal.svg"
import PayPalCreditLogo from "../../../images/logo-paypal-credit.svg"
import { ErrorBanner } from "../../../components/banner"

type PaymentFormProps = {
  invalidFields: Array<FormErrors>
  referralCode: string
  onReferralChange: (val: string) => void
  paymentType: PaymentOptions
  onSetPaymentType: (val: PaymentOptions) => void
  cardNumber: string
  onCardNumberChange: (val: string) => void
  expiration: string
  onExpirationChange: (val: string) => void
  CVV: string
  onCVVChange: (val: string) => void
  cardName: string
  onCardNameChange: (val: string) => void
  billing1: string
  onBilling1Change: (val: string) => void
  billing2: string
  onBilling2Change: (val: string) => void
  city: string
  onCityChange: (val: string) => void
  state: string
  onStateChange: (val: string) => void
  zipCode: string
  onZipCodeChange: (val: string) => void
}

type StateSelectProps = {
  error: string
  value: string
  onChange: (state: string) => void
}

const StateSelect = (props: StateSelectProps) => {
  return (
    <div className="px-2">
      <label
        htmlFor="state"
        className="block text-sm font-medium text-gray-700"
      >
        {Content.billingStateLabel}
        <span className="mx-2 text-red font-bold">*</span>
        {props.error && (
          <span className="mx-2 text-red text-xs">{props.error}</span>
        )}
      </label>
      <div className="border rounded-md p-2 mt-1 focus-within:border-secondary-500 flex-1 block w-full sm:text-sm border-gray-300">
        <select
          value={props.value ?? ""}
          name="state"
          id="state"
          className="w-full outline-none"
          autoComplete="address-level1"
          required={true}
          onChange={e => props.onChange(e.target.value)}
        >
          <option value="" disabled={true}>
            --- Select state ---
          </option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </div>
    </div>
  )
}

export function PaymentForm(props: PaymentFormProps) {
  const [showReferralCode, setShowReferralCode] = useState(false)

  function checkError(error: FormErrors) {
    return props.invalidFields?.includes(error)
  }

  let referralError,
    cardNameError,
    cardNumberError,
    expirationError,
    cvvError,
    billingError,
    cityError,
    stateError,
    zipcodeError = ""

  if (checkError(FormErrors.referralCodeInvalid)) {
    referralError = Content.referralInvalid
  }
  if (checkError(FormErrors.cardNameMissing)) {
    cardNameError = Content.fieldRequired
  }
  if (checkError(FormErrors.cardNumberMissing)) {
    cardNumberError = Content.fieldRequired
  }
  if (checkError(FormErrors.expirationMissing)) {
    expirationError = Content.fieldRequired
  }
  if (checkError(FormErrors.CVVMissing)) {
    cvvError = Content.fieldRequired
  }
  if (checkError(FormErrors.billing1Missing)) {
    billingError = Content.fieldRequired
  }
  if (checkError(FormErrors.billing1Missing)) {
    cityError = Content.fieldRequired
  }
  if (checkError(FormErrors.stateMissing)) {
    stateError = Content.fieldRequired
  }
  if (checkError(FormErrors.zipCodeMissing)) {
    zipcodeError = Content.fieldRequired
  } else if (checkError(FormErrors.zipCodeInvalid)) {
    zipcodeError = Content.zipcodeInvalid
  }

  const paymentInvalid = checkError(FormErrors.paymentInvalid)

  const paymentOptions = [
    {
      value: PaymentOptions.credit,
      name: <span>{PaymentOptions.credit}</span>,
    },
    {
      value: PaymentOptions.paypal,
      name: <img src={PayPalLogo} alt={PaymentOptions.paypal} />,
    },
    {
      value: PaymentOptions.paypalCredit,
      name: <img src={PayPalCreditLogo} alt={PaymentOptions.paypalCredit} />,
    },
  ]

  function handleTabClick(event: React.MouseEvent, value: PaymentOptions) {
    event.preventDefault()
    handlePaymentChange(value)
  }

  function handlePaymentChange(value: PaymentOptions) {
    props.onSetPaymentType(value)
    if (value !== PaymentOptions.credit) {
      props.onCardNumberChange("")
      props.onExpirationChange("")
      props.onCVVChange("")
      props.onCardNameChange("")
    }
  }

  const cardSelected = props.paymentType === PaymentOptions.credit

  return (
    <Section id="payment-details" header={Content.paymentDetailsHeader}>
      {paymentInvalid && <ErrorBanner message={Content.paymentInvalid} />}
      <div className="mb-4">
        {showReferralCode ? (
          <div className="flex items-end">
            <TextField
              label={Content.referralCodeLabel}
              id="referral-code"
              error={referralError}
              value={props.referralCode}
              onChange={props.onReferralChange}
            />
            <button
              className="p-2"
              onClick={() => {
                setShowReferralCode(false)
                props.onReferralChange("")
              }}
            >
              <XIcon className="text-gray-500 w-6 h-6" />
            </button>
          </div>
        ) : (
          <button
            className="mx-4 text-secondary tracking-wider cursor-pointer font-bold"
            onClick={() => setShowReferralCode(true)}
          >
            {Content.addReferralCode}
          </button>
        )}
      </div>
      <div className="flex flex-col space-y-4">
        <div className="sm:hidden mx-2">
          <div className="border rounded-md p-2 mt-1 focus-within:border-secondary-500 flex-1 block w-full sm:text-sm border-gray-300">
            <select
              id="tabs"
              name="tabs"
              className="w-full outline-none"
              defaultValue={props.paymentType}
              onChange={e => handlePaymentChange(e.target.value)}
            >
              {paymentOptions.map(tab => (
                <option key={tab.value} value={tab.value}>
                  {tab.value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="hidden sm:block mx-2">
          <nav className="flex space-x-4" aria-label="Tabs">
            {paymentOptions.map(tab => (
              <button
                key={tab.value}
                className={`
                  px-3 py-2 font-medium text-sm rounded-md border
                  ${
                    props.paymentType === tab.value
                      ? "bg-secondary-50 text-secondary-600"
                      : "text-gray-500 hover:text-gray-700"
                  }
                `}
                onClick={e => handleTabClick(e, tab.value)}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
        {/* Payment form */}
        <div
          className={`
            space-y-4
            ${cardSelected ? "" : "hidden"}
          `}
        >
          <TextField
            label={Content.cardNameLabel}
            id="cc-name"
            autoComplete="cc-name"
            required={cardSelected}
            error={cardNameError}
            value={props.cardName}
            onChange={props.onCardNameChange}
          />
          <TextField
            label={Content.cardLabel}
            id="card-number"
            autoComplete="cc-number"
            required={cardSelected}
            placeholder={Content.cardPlaceholder}
            error={cardNumberError}
            value={props.cardNumber}
            onChange={props.onCardNumberChange}
          />
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0">
            <CreditDateField
              label={Content.cardExpLabel}
              id="expiration-date"
              autoComplete="cc-exp"
              required={cardSelected}
              placeholder={Content.cardExpPlaceholder}
              error={expirationError}
              value={props.expiration}
              onChange={props.onExpirationChange}
            />
            <TextField
              label={Content.cardCVVLabel}
              id="cvv"
              autoComplete="cc-csc"
              required={cardSelected}
              placeholder={Content.cardCVVPlaceholder}
              error={cvvError}
              value={props.CVV}
              onChange={props.onCVVChange}
            />
          </div>
        </div>

        <TextField
          label={Content.billing1Label}
          id="address-1"
          autoComplete="street-address"
          required={true}
          placeholder={Content.billing1Placeholder}
          error={billingError}
          value={props.billing1}
          onChange={props.onBilling1Change}
        />
        <TextField
          label={Content.billing2Label}
          id="address-2"
          value={props.billing2}
          onChange={props.onBilling2Change}
        />
        <div className="grid grid-cols-1 space-y-4 md:grid-cols-3 md:space-y-0">
          <TextField
            label={Content.billingCityLabel}
            id="city"
            autoComplete="address-level2"
            required={true}
            error={cityError}
            value={props.city}
            onChange={props.onCityChange}
          />
          <StateSelect
            error={stateError ?? ""}
            value={props.state}
            onChange={props.onStateChange}
          />
          <TextField
            label={Content.billingZipCodeLabel}
            id="zipcode"
            autoComplete="postal-code"
            required={true}
            error={zipcodeError}
            value={props.zipCode}
            onChange={props.onZipCodeChange}
          />
        </div>
      </div>
    </Section>
  )
}

export default PaymentForm
