import React from "react"
import TextField, { PhoneField } from "../../../components/textfield"
import { Section } from "./Section"
import { Content, FormErrors } from "../index"

type PayerFormProps = {
  invalidFields: Array<FormErrors>
  payerFirstName: string
  onPayerFirstNameChange: (val: string) => void
  payerLastName: string
  onPayerLastNameChange: (val: string) => void
  payerEmail: string
  onPayerEmailChange: (val: string) => void
  payerPhone: string
  onPayerPhoneChange: (val: string) => void
}

export function PayerForm(props: PayerFormProps) {
  function checkError(error: FormErrors) {
    return props.invalidFields?.includes(error)
  }

  let firstNameError,
    lastNameError,
    emailError,
    phoneError = ""
  if (checkError(FormErrors.payerFirstNameMissing)) {
    firstNameError = Content.fieldRequired
  }
  if (checkError(FormErrors.payerLastNameMissing)) {
    lastNameError = Content.fieldRequired
  }

  if (checkError(FormErrors.payerPhoneInvalid)) {
    phoneError = Content.phoneInvalid
  }

  if (checkError(FormErrors.payerEmailInvalid)) {
    emailError = Content.emailInvalid
  }

  return (
    <Section
      id="payer-details"
      header={Content.payerDetailsHeader}
      description={Content.payerDetailsDescription}
    >
      <div className="md:flex items-center">
        <div className="w-full">
          <TextField
            label={Content.payerFirstNameLabel}
            id="parent-first-name"
            autoComplete="given-name"
            required={true}
            error={firstNameError}
            value={props.payerFirstName}
            onChange={props.onPayerFirstNameChange}
          />
        </div>
        <div className="my-4 md:my-0 w-full">
          <TextField
            label={Content.payerLastNameLabel}
            id="parent-last-name"
            autoComplete="family-name"
            required={true}
            error={lastNameError}
            value={props.payerLastName}
            onChange={props.onPayerLastNameChange}
          />
        </div>
      </div>
      <div className="my-4">
        <TextField
          label={Content.payerEmailLabel}
          id="email"
          autoComplete="email"
          type="email"
          required={true}
          error={emailError}
          value={props.payerEmail}
          onChange={props.onPayerEmailChange}
        />
      </div>
      <div className="mt-4">
        <PhoneField
          label={Content.payerPhoneLabel}
          id="parent-mobile"
          autoComplete="tel"
          required={true}
          error={phoneError}
          value={props.payerPhone}
          onChange={props.onPayerPhoneChange}
        />
      </div>
    </Section>
  )
}

export default PayerForm
