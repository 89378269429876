import React from "react"
import TextField, { PhoneField } from "../../../components/textfield"
import { Section } from "./Section"
import { Content, FormErrors } from "../index"

type StudentFormProps = {
  invalidFields: Array<FormErrors>
  studentName: string
  onStudentNameChange: (val: string) => void
  studentPhone: string
  onStudentPhoneChange: (val: string) => void
  studentEmail: string
  onStudentEmailChange: (val: string) => void
}

export function StudentForm(props: StudentFormProps) {
  function checkError(error: FormErrors) {
    return props.invalidFields?.includes(error)
  }

  let nameError,
    phoneError,
    emailError = ""
  if (checkError(FormErrors.studentNameMissing)) {
    nameError = Content.fieldRequired
  }
  if (checkError(FormErrors.studentInfoMissing)) {
    emailError = Content.studentInfoMissing
    // } else if (checkError(FormErrors.studentEmailMissing)) {
    //   emailError = Content.fieldRequired
    // } else if (checkError(FormErrors.studentEmailInvalid)) {
    //   emailError = Content.emailInvalid
  } else if (checkError(FormErrors.studentPhoneMissing)) {
    phoneError = Content.fieldRequired
  } else if (checkError(FormErrors.studentPhoneInvalid)) {
    phoneError = Content.phoneInvalid
  }

  return (
    <Section
      id="student-details"
      header={Content.studentDetailsHeader}
      description={Content.studentDetailsDescription}
    >
      <div className="mb-4">
        <TextField
          label={Content.studentFirstNameLabel}
          id="student-first-name"
          autoComplete="given-name"
          required={true}
          error={nameError}
          value={props.studentName}
          onChange={props.onStudentNameChange}
        />
      </div>
      {/* <div className="mt-4">
        <TextField
          label={Content.studentEmailLabel}
          id="student-email"
          type="email"
          autoComplete="email"
          error={emailError}
          value={props.studentEmail}
          onChange={props.onStudentEmailChange}
        />
      </div> */}
      <div className="mt-4">
        <PhoneField
          label={Content.studentPhoneLabel}
          id="student-mobile"
          autoComplete="tel"
          error={phoneError}
          required={true}
          value={props.studentPhone}
          onChange={props.onStudentPhoneChange}
        />
      </div>
    </Section>
  )
}

export default StudentForm
