import React, { useState, useEffect } from "react"
import { isValidPhoneNumber } from "libphonenumber-js"
import { useQueryParam, StringParam } from "use-query-params"
import { interpolateString } from "../../helpers/Strings"
import { Routes, navigate } from "../../data/routes"
import { Plans, PlanType, StorageField, PaymentOptions } from "../../data/plans"

import { ErrorBanner } from "../../components/banner"
import { SecondaryOutlineButton, SubmitButton } from "../../components/button"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { LockClosedIcon, CheckCircleIcon } from "@heroicons/react/outline"

import { Section } from "./forms/Section"
import { StudentForm } from "./forms/StudentForm"
import { PayerForm } from "./forms/PayerForm"
import { PaymentForm } from "./forms/PaymentForm"
import { PurchaseData as FormData } from "../../data/plans"

export const Content = {
  pageName: "Purchase",
  description: "Unlimited math tutoring is minutes away",
  header: "Unlimited math tutoring is minutes away",
  disclaimer: `We take privacy very seriously and never
    share or sell Student or Payer details`,

  // Plan details
  planDetailsHeader: "Plan Details",
  planStartLabel: "Plan starts",
  trialEndLabel: "Trial ends",
  planEndLabel: "Plan ends",
  planMonthlyCost: "{{durationString}} at ${{monthlyPrice}}/mo",
  totalCost: "You pay",

  // Trial details
  trialDetailsHeader: "Trial Details",
  trialDescription: "{{days}} Day Risk-Free Trial",
  trialDetails: [
    `Your trial period starts after purchase and ends on {{date}}`,
    `If Yup is not the right fit, email support@yup.com within the
      trial period to receive a full refund`,
    `Cancellations requested outside of the trial period are subject
      to a $100 fee`,
  ],

  // Form errors
  invalidForm: "Please fix the error(s) below",
  fieldRequired: "Field is required",
  phoneInvalid: "Phone number is invalid",
  emailInvalid: "Email is invalid",
  referralInvalid: "Code is invalid",
  zipcodeInvalid: "Zip code is invalid",
  studentInfoMissing: "Must provide student's email or phone number",
  paymentInvalid:
    "There was an error with this payment method. Please re-enter your information",

  // Student details
  studentDetailsHeader: "Student Account Details",
  studentDetailsDescription: `Tutors only see student's first name. We'll
    text sign-up instructions to your student's phone number to get started`,
  studentFirstNameLabel: "Student's First Name",
  studentPhoneLabel: "Student's Mobile Number",
  studentEmailLabel: "Student's Email",

  // Payer details
  payerDetailsHeader: "Payer Account Details",
  payerDetailsDescription: `We will send receipts and Yup resources to this
    email address. We will send student progress updates to this phone number`,
  payerFirstNameLabel: "Payer's First Name",
  payerLastNameLabel: "Payer's Last Name",
  payerEmailLabel: "Payer's Email Address",
  payerPhoneLabel: "Payer's Mobile Number",

  // Payment details
  paymentDetailsHeader: "Payment Details",
  referralCodeLabel: "Referral code",
  addReferralCode: "+ Add Referral Code",
  cardLabel: "Card Number",
  cardPlaceholder: "4111 1111 1111 1111",
  cardExpLabel: "Expiration Date",
  cardExpPlaceholder: "10/29",
  cardCVVLabel: "CVV",
  cardCVVPlaceholder: "123",
  cardNameLabel: "Name as it appears on the card",
  billing1Label: "Billing Address Line 1",
  billing1Placeholder: "Street Address, P.O. Box",
  billing2Label: "Billing Address Line 2",
  billingCityLabel: "City",
  billingStateLabel: "State",
  billingStatePlaceholder: "CA",
  billingZipCodeLabel: "Zip Code",

  // Checkout
  confirmationDisclaimer: `You will not be charged until you confirm
    your details on the next page.`,
  reselctPlan: "Reselect plan",
  reviewPlan: "Review Plan",
}

export enum FormErrors {
  studentNameMissing,
  studentPhoneMissing,
  studentPhoneInvalid,
  // studentEmailMissing,
  // studentEmailInvalid,
  studentInfoMissing,
  payerFirstNameMissing,
  payerLastNameMissing,
  payerEmailMissing,
  payerEmailInvalid,
  payerPhoneMissing,
  payerPhoneInvalid,
  referralCodeInvalid,
  paymentInvalid,
  cardNameMissing,
  cardNumberMissing,
  expirationMissing,
  CVVMissing,
  billing1Missing,
  cityMissing,
  stateMissing,
  zipCodeMissing,
  zipCodeInvalid,
}

function isValidEmail(email: string) {
  return /^.+@.+\..+$/.test(email)
}
function isValidZipCode(zipCode: string) {
  return /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/g.test(zipCode)
}

const FormValidators = [
  (data: FormData) => !data.studentName.trim() && FormErrors.studentNameMissing,
  (data: FormData) => {
    // if (data.studentEmail.trim()) return
    if (!data.studentPhone.trim()) return FormErrors.studentPhoneMissing
    else if (!isValidPhoneNumber(data.studentPhone))
      return FormErrors.studentPhoneInvalid
  },
  // (data: FormData) => {
  //   if (!data.studentEmail.trim()) return FormErrors.studentEmailMissing
  //   else if (!isValidEmail(data.studentEmail))
  //     return FormErrors.studentEmailInvalid
  // },
  // (data: FormData) =>
  //   !data.studentEmail.trim() &&
  //   !data.studentPhone.trim() &&
  //   FormErrors.studentInfoMissing,
  (data: FormData) =>
    !data.payerFirstName.trim() && FormErrors.payerFirstNameMissing,
  (data: FormData) =>
    !data.payerLastName.trim() && FormErrors.payerLastNameMissing,
  (data: FormData) => {
    if (!data.payerEmail.trim()) return FormErrors.payerEmailMissing
    else if (!isValidEmail(data.payerEmail)) return FormErrors.payerEmailInvalid
  },
  (data: FormData) => {
    if (!data.payerPhone.trim()) return FormErrors.payerPhoneMissing
    else if (!isValidPhoneNumber(data.payerPhone))
      return FormErrors.payerPhoneInvalid
  },
]
const AddressValidators = [
  (data: FormData) => !data.billing1.trim() && FormErrors.billing1Missing,
  (data: FormData) => !data.city.trim() && FormErrors.cityMissing,
  (data: FormData) => !data.state.trim() && FormErrors.stateMissing,
  (data: FormData) => {
    if (!data.zipCode.trim()) return FormErrors.zipCodeMissing
    else if (!isValidZipCode(data.zipCode)) return FormErrors.zipCodeInvalid
  },
]

function Header() {
  return (
    <div className="max-w-7xl mx-auto pt-16 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
          {Content.header}
        </p>
        <p className="max-w-5xl mt-5 mx-auto text-xl flex text-gray-500 justify-center">
          <LockClosedIcon className="h-6 w-6 mr-4 hidden sm:block" />
          {Content.disclaimer}
        </p>
      </div>
    </div>
  )
}

function formatDate(date: Date) {
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

function PlanDetails(props: { plan: PlanType }) {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/data-display/description-lists#component-e1b5917b21bbe76a73a96c5ca876225f

  const today = new Date()
  const trialEnd = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + props.plan.trial_days
  )
  const planEnd = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + props.plan.duration_days
  )

  const { title, durationString, monthlyPrice, price } = props.plan

  return (
    <div className="max-w-4xl mx-auto overflow-hidden">
      <div className="px-4 pb-5 text-center sm:text-left sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      </div>
      <div className="border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:py-5 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              {Content.planStartLabel}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
              {formatDate(today)}
            </dd>
          </div>
          {props.plan.trial_eligible && (
            <div className="py-4 sm:py-5 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {Content.trialEndLabel}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                {formatDate(trialEnd)}
              </dd>
            </div>
          )}
          <div className="py-4 sm:py-5 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              {Content.planEndLabel}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
              {formatDate(planEnd)}
            </dd>
          </div>
          <hr />
          <div className="py-4 sm:py-5 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-6 items-end">
            <dt className="font-medium text-gray-500">
              <p className="text-sm">
                {interpolateString(Content.planMonthlyCost, {
                  durationString,
                  monthlyPrice,
                })}
              </p>
              <p className="font-bold mt-1 text-lg">{Content.totalCost}</p>
            </dt>
            <dd className="text-gray-900 sm:mt-0 sm:col-span-2 font-bold text-xl text-right">
              ${price.toLocaleString()}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

function TrialDetails(props: { plan: PlanType }) {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/data-display/description-lists#component-e1b5917b21bbe76a73a96c5ca876225f

  const today = new Date()
  const trialEnd = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + props.plan.trial_days
  )

  return (
    <div className="max-w-4xl mx-auto overflow-hidden">
      <div className="pb-5 sm:px-6">
        <h3 className="text-lg text-center sm:text-left leading-6 font-medium text-gray-900">
          {interpolateString(Content.trialDescription, {
            days: props.plan.trial_days,
          })}
        </h3>
      </div>
      <div className="border-t border-gray-200 pt-4 sm:p-0 text-gray-900">
        {Content.trialDetails.map(detail => (
          <div
            key={detail}
            className="pt-5 text-base flex flex-col text-center sm:text-left sm:flex-row items-start"
          >
            <CheckCircleIcon className="h-6 w-6 text-secondary my-2 mx-auto sm:mx-4 sm:my-0" />
            <p>{interpolateString(detail, { date: formatDate(trialEnd) })}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

interface OrderFormProps {
  plan: PlanType
  onSubmit: () => void
}

function OrderForm(props: OrderFormProps) {
  const cachedData = JSON.parse(sessionStorage[StorageField] ?? "{}")

  const [invalidFields, setInvalidFields] = useState<Array<any>>([])
  const [studentName, setStudentName] = useState(cachedData.studentName ?? "")
  const [studentPhone, setStudentPhone] = useState(
    cachedData.studentPhone ?? ""
  )
  const [studentEmail, setStudentEmail] = useState(
    cachedData.studentEmail ?? ""
  )
  const [payerFirstName, setPayerFirstName] = useState(
    cachedData.payerFirstName ?? ""
  )

  const [payerLastName, setPayerLastName] = useState(
    cachedData.payerLastName ?? ""
  )
  const [payerEmail, setPayerEmail] = useState(cachedData.payerEmail ?? "")
  const [payerPhone, setPayerPhone] = useState(cachedData.payerPhone ?? "")

  const [referralCode, setReferralCode] = useState(
    cachedData.referralCode ?? ""
  )
  const [paymentType, setPaymentType] = useState(
    cachedData.paymentType ?? PaymentOptions.credit
  )
  const [cardName, setCardName] = useState(cachedData.cardName ?? "")
  const [cardNumber, setCardNumber] = useState("")
  const [expiration, setExpiration] = useState("")
  const [CVV, setCVV] = useState("")
  const [billing1, setBilling1] = useState(cachedData.billing1 ?? "")
  const [billing2, setBilling2] = useState(cachedData.billing2 ?? "")
  const [city, setCity] = useState(cachedData.city ?? "")
  const [state, setState] = useState(cachedData.state ?? "")
  const [zipCode, setZipcode] = useState(cachedData.zipCode ?? "")

  const [paymentInfo, setPaymentInfo] = useState({})

  useEffect(() => {
    sessionStorage[StorageField] = JSON.stringify(getData())
  }, [
    studentName,
    studentPhone,
    studentEmail,
    payerFirstName,
    payerLastName,
    payerEmail,
    payerPhone,
    referralCode,
    paymentType,
    cardName,
    billing1,
    billing2,
    city,
    state,
    zipCode,
    paymentInfo,
  ])

  function getData() {
    return {
      studentName,
      studentPhone,
      // studentEmail,
      payerFirstName,
      payerLastName,
      payerEmail,
      payerPhone,
      referralCode,
      paymentType,
      cardName,
      billing1,
      billing2,
      city,
      state,
      zipCode,
      paymentInfo,
    } as FormData
  }

  /***
   * VALIDATION
   ***/
  async function validate() {
    const data = getData()
    console.log("DATA", data)
    const invalidFields = FormValidators.map(validator => validator(data))
      .concat(await getInvalidPaymentFields())
      .concat(await getInvalidReferralCodeFields())
      .filter(Boolean)
    console.log("INVALID FIELDS", invalidFields)
    setInvalidFields(invalidFields)
    return !invalidFields.length
  }

  async function getInvalidReferralCodeFields() {
    if (referralCode && (await Plans.validateReferralCode(referralCode)))
      return [FormErrors.referralCodeInvalid]
    return [] as any
  }

  async function getInvalidPaymentFields() {
    const data = getData()
    const invalidFields: Array<any> = AddressValidators.map(validator =>
      validator(data)
    ).filter(Boolean)

    if (paymentType === PaymentOptions.credit) {
      ;[
        () => !cardName.trim() && FormErrors.cardNameMissing,
        () => !cardNumber.trim() && FormErrors.cardNumberMissing,
        () => !expiration.trim() && FormErrors.expirationMissing,
        () => !CVV.trim() && FormErrors.CVVMissing,
      ].forEach(validator => {
        const error = validator()
        if (error) invalidFields.push(error)
      })

      if (!invalidFields.length) {
        const response = await Plans.validateCreditCard({
          email: payerEmail,
          name: cardName,
          number: cardNumber,
          expiration: expiration,
          cvv: Number(CVV),
          zipcode: zipCode,
        })
        if (response.status === "error") {
          invalidFields.push(FormErrors.paymentInvalid)
        } else {
          setPaymentInfo(response)
        }
      }
    } else {
      const response = await Plans.startPaypalFlow(
        paymentType === PaymentOptions.paypalCredit
      )
      if (response.status === "error") {
        invalidFields.push(FormErrors.paymentInvalid)
      } else {
        setPaymentInfo(response)
      }
    }
    return invalidFields
  }

  async function onSubmit(event: React.FormEvent) {
    event.preventDefault()
    setInvalidFields([])
    if (await validate()) {
      props.onSubmit()
    } else {
      document
        .getElementById("error-banner")
        ?.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  return (
    <form id="hosted-fields" className="space-y-6" onSubmit={onSubmit}>
      <div className="px-4 py-5 sm:p-6">
        <div className="max-w-7xl mx-auto">
          {/* Error banner */}
          {invalidFields.length > 0 && (
            <div
              id="error-banner"
              className="md:grid md:grid-cols-4 md:gap-6 pt-16"
            >
              <div className="md:col-span-1"></div>
              <div className="col-span-3 w-full px-0 md:px-8">
                <ErrorBanner message={Content.invalidForm} />
              </div>
            </div>
          )}

          {/* Plan Details */}
          <div className="pt-8">
            <Section id="plan-details" header={Content.planDetailsHeader}>
              <PlanDetails plan={props.plan} />
            </Section>
          </div>

          {/* Trial Details */}
          {props.plan.trial_eligible && (
            <Section id="trial-details" header={Content.trialDetailsHeader}>
              <TrialDetails plan={props.plan} />
            </Section>
          )}

          {/* Student Account Details */}
          <StudentForm
            invalidFields={invalidFields}
            studentName={studentName}
            onStudentNameChange={setStudentName}
            studentPhone={studentPhone}
            onStudentPhoneChange={setStudentPhone}
            studentEmail={studentEmail}
            onStudentEmailChange={setStudentEmail}
          />

          {/* Payer Account Details */}
          <PayerForm
            invalidFields={invalidFields}
            payerFirstName={payerFirstName}
            onPayerFirstNameChange={setPayerFirstName}
            payerLastName={payerLastName}
            onPayerLastNameChange={setPayerLastName}
            payerEmail={payerEmail}
            onPayerEmailChange={setPayerEmail}
            payerPhone={payerPhone}
            onPayerPhoneChange={setPayerPhone}
          />

          {/* Payment Details */}
          <PaymentForm
            invalidFields={invalidFields}
            referralCode={referralCode}
            onReferralChange={setReferralCode}
            paymentType={paymentType}
            onSetPaymentType={setPaymentType}
            cardName={cardName}
            onCardNameChange={setCardName}
            cardNumber={cardNumber}
            onCardNumberChange={setCardNumber}
            expiration={expiration}
            onExpirationChange={setExpiration}
            CVV={CVV}
            onCVVChange={setCVV}
            billing1={billing1}
            onBilling1Change={setBilling1}
            billing2={billing2}
            onBilling2Change={setBilling2}
            city={city}
            onCityChange={setCity}
            state={state}
            onStateChange={setState}
            zipCode={zipCode}
            onZipCodeChange={setZipcode}
          />

          {/* Total */}
          <div className="md:grid md:grid-cols-4 md:gap-6">
            <div className="md:col-span-1"></div>
            <div className="mt-5 md:mt-0 md:col-span-3 mx-0 md:mx-8">
              <p className="text-center md:text-left">
                {Content.confirmationDisclaimer}
              </p>
              <div className="my-4 flex flex-col md:flex-row justify-end items-center">
                <a
                  className="ml-0 my-4 md:mx-4"
                  href="https://www.braintreegateway.com/merchants/yy6y7kj6ghprmg77/verified"
                  target="_blank"
                >
                  <img
                    src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png"
                    alt="Braintree logo"
                    width="164px"
                    height="44px"
                  />
                </a>
                <SecondaryOutlineButton
                  href={Routes.plans}
                  text={Content.reselctPlan}
                />
                <SubmitButton text={Content.reviewPlan} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const Purchase = () => {
  const [sku] = useQueryParam("sku", StringParam)
  const [plan, setPlan] = useState<PlanType>()

  useEffect(() => {
    ;(async () => {
      try {
        Plans.onFormRender()

        const plans = await Plans.get(),
          plan = plans.find(plan => plan.sku === sku)
        if (plan) {
          setPlan(plan)
        } else {
          navigate(Routes.plans)
        }
      } catch (error) {
        // TODO: Handle error
      }
    })()
  }, [])

  function onSubmit() {
    navigate(`${Routes.reviewPurchase}?sku=${plan?.sku}`)
  }

  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.purchase}
      />
      <Header />
      {plan && <OrderForm plan={plan} onSubmit={onSubmit} />}
    </Layout>
  )
}

export default Purchase
