import React from "react"
import { XIcon } from "@heroicons/react/outline"

type BannerProps = {
  message: string
  onDismiss?: () => void
}

export function SuccessBanner(props: BannerProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/elements/banners#component-1a63aa510db7761c581486c7259487e8
  return (
    <div className="bg-primary-200 text-gray-900">
      <div className="max-w-7xl mx-auto my-2 py-2 px-3">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <p className="ml-3 font-medium">{props.message}</p>
          </div>
          {props.onDismiss && (
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-primary-100 focus:outline-none sm:-mr-2"
                onClick={props.onDismiss}
              >
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export function ErrorBanner(props: BannerProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/elements/banners#component-1a63aa510db7761c581486c7259487e8
  return (
    <div className="bg-red text-white">
      <div className="max-w-7xl mx-auto my-2 py-2 px-3">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <p className="ml-3 font-medium">{props.message}</p>
          </div>
          {props.onDismiss && (
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md focus:outline-none sm:-mr-2"
                onClick={props.onDismiss}
              >
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
